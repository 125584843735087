var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 px-5 d-flex flex-row pt-8"},[_c('v-col',{attrs:{"cols":"9"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("invitations.title")))]),(!_vm.isDienstleister)?_c('p',{staticClass:"primary--text pt-4"},[_vm._v(_vm._s(_vm.$t("invitations.title-description")))]):_vm._e(),(_vm.isDienstleister)?_c('p',{staticClass:"primary--text pt-4"},[_vm._v(_vm._s(_vm.$t("invitations.title-description-dienstleister")))]):_vm._e()]),_c('v-col',{staticClass:"align-content-end",attrs:{"cols":"3"}},[_c('div',{staticStyle:{"float":"right"}},[_c('v-btn',{staticClass:"primary--text cta bold",class:{ 'op-50': _vm.isMakler() },attrs:{"id":"invitations-open-invitation-form","rounded":"","color":"secondary","depressed":"","large":"","disabled":_vm.isMakler()},on:{"click":function($event){return _vm.openInvitationForm()}}},[_c('v-icon',{staticClass:"prepend-icon",attrs:{"size":"20"}},[_vm._v("$verka_plus")]),_vm._v(_vm._s(_vm.$t("invitations.send-new-invitation")))],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"verka-table verka-action-table invitations-table",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('invitations.loading-data'),"hide-default-footer":"","headers":_vm.headers,"items":_vm.invitations},scopedSlots:_vm._u([{key:"item.vsnr",fn:function(ref){
var item = ref.item;
return [(_vm.isVerkaIdNotEqualPid(item.pid))?[_vm._v(" "+_vm._s(item.pid)+" ")]:_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","nudge-left":"50"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-row-button-end",class:{ 'op-50': _vm.isMakler() },attrs:{"color":"background","depressed":"","disabled":_vm.isMakler(),"elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"op-38",attrs:{"size":"20","color":"primary"}},[_vm._v("$verka_more")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"width":"100%","height":"60%"},on:{"click":function($event){return _vm.openReInvitationForm(item)}}},[_c('v-list-item-title',{staticClass:"cta"},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("$verka_send")]),_vm._v(_vm._s(_vm.$t("employees.send-new-invite")))],1)],1)],1)],1)]}}])},[_c('template',{slot:"no-data"},[_c('p',{staticStyle:{"margin":"auto","width":"50%","padding":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("table.no-data-available"))+" ")])])],2),(!_vm.isLoading && _vm.totalPages > 0)?_c('v-pagination',{staticClass:"elevation-0 primary--text",attrs:{"color":"background","length":_vm.totalPages,"total-visible":_vm.pagination_total_visible,"prev-icon":"$verka_left_full","next-icon":"$verka_right_full"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"800"},on:{"click:outside":function($event){return _vm.clickedOutside()}},model:{value:(_vm.showNewInvitationPopup),callback:function ($$v) {_vm.showNewInvitationPopup=$$v},expression:"showNewInvitationPopup"}},[_c('invitation-form',{on:{"logout-session":function($event){return _vm.logoutSession()}}})],1),(_vm.showReInvitationPopup)?_c('v-dialog',{attrs:{"max-width":"800"},on:{"click:outside":function($event){return _vm.clickedOutside()}},model:{value:(_vm.setShowReInvitationPopup),callback:function ($$v) {_vm.setShowReInvitationPopup=$$v},expression:"setShowReInvitationPopup"}},[_c('reinvitation-form')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }