var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.box)?_c('v-col',{key:_vm.box.name,attrs:{"cols":12}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.box.title)?_c('h4',[_vm._v(_vm._s(_vm.box.title))]):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[(this.$vuetify.breakpoint.name == 'xs')?_c('router-link',{staticClass:"bold verka-link-action mt-1 primary--text cta",staticStyle:{"float":"right"},attrs:{"to":{ name: 'fondsanteile' }}},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("company-data.go-to-fund-shares")))]),_c('v-icon',{attrs:{"color":"primary op-38 ","size":"12"}},[_vm._v("$verka_right")])],1):_vm._e()],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"boxHeader mt-6 mb-2 pt-3 pb-3"},[_c('h5',[_vm._v(_vm._s(_vm.box.name))]),(_vm.box.desc)?_c('v-card-subtitle',{staticClass:"metadata-text"},[_vm._v(_vm._s(_vm.box.desc))]):_vm._e(),(_vm.box.icon)?_c('v-icon',{staticClass:"op-38",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.box.icon))]):_vm._e()],1),_c('v-row',[_vm._l((_vm.box.subBoxes),function(subBox){return [_c('v-col',{key:subBox.value,staticClass:"card-flex min-height mt-4 mb-4",attrs:{"align":"center","cols":6}},[_c('h6',{staticClass:"boxValue"},[_vm._v(_vm._s(_vm.printValue(subBox)))]),_c('v-card-subtitle',{staticClass:"card-subtitle",attrs:{"color":"primary"},domProps:{"innerHTML":_vm._s(subBox.key)}})],1),(subBox.divider)?_c('v-divider',{key:subBox.key,attrs:{"vertical":""}}):_vm._e()]})],2),(this.$vuetify.breakpoint.name != 'xs')?_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-data-table',{staticClass:"verka-table ml-8 mr-8 text-right",attrs:{"headers":_vm.headers,"items":_vm.box.fondsanteile,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.isin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(item.isin)+" ")])]}},{key:"item.anteile",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right nowrap"},[_vm._v(" "+_vm._s(_vm.formatter.fondanteileFormatter(item.anteile))+" ")])]}},{key:"item.kurs",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right nowrap"},[_vm._v(" "+_vm._s(_vm.formatter.moneyFormatter(item.kurs))+" ")])]}},{key:"item.guthaben",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right nowrap"},[_vm._v(" "+_vm._s(_vm.formatter.moneyFormatter(item.guthaben))+" ")])]}},{key:"item.beitragsaufteilung",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right nowrap"},[_vm._v(" "+_vm._s(_vm.formatter.percentFormatter(item.beitragsaufteilung))+" ")])]}}],null,false,3289868598)})],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }