var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSomething)?_c('v-row',{staticClass:"mt-0 px-5 d-flex flex-row pt-8"},[_c('v-col',{attrs:{"cols":"9"}},[_c('h2',{attrs:{"id":"my-contracts-title"}},[_vm._v(_vm._s(_vm.$t("contracts.title")))]),_c('p',{staticClass:"primary--text pt-4",attrs:{"id":"my-contract-description"}},[_vm._v(_vm._s(_vm.$t("contracts.title-description")))])]),_c('v-col',{staticClass:"background-white rounded-5",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"contracts-table verka-table",attrs:{"loading-text":_vm.$t('contracts.loading-data'),"loading":_vm.isLoading,"options":_vm.options,"headers":_vm.headers,"items":_vm.contracts,"hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.vtrNr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vtrNr)+" "),_c('br'),_c('p')]}},{key:"item.produkt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("product-name." + item.produkt))+" "),_c('br'),(!_vm.isMobile())?_c('span',[_vm._v(_vm._s(item.kollVtrNr))]):_vm._e()]}},{key:"item.anName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.anName)+" "),_c('br'),_vm._v(" "+_vm._s(item.anVorname)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('contract-status.' + item.status))}})]}},{key:"item.beitrag",fn:function(ref){
var item = ref.item;
return [(item.beitrag != null && !item.status.includes('rentner'))?_c('div',[_vm._v(" "+_vm._s(_vm.$t("beitragszahlweise." + item.beitragszahlweise))),_c('br'),_vm._v(" "+_vm._s(item.status == "anwaerter-einmalbeitragszahlung" && _vm.formatter.moneyFormatter(item.beitrag) == "0,00 €" ? "" : _vm.formatter.moneyFormatter(item.beitrag))+" ")]):_vm._e(),(item.beitrag == null && !item.status.includes('rentner'))?_c('div',[_vm._v(" "+_vm._s(item.zahlweise)),_c('br'),_vm._v(" "+_vm._s("-/-")+" ")]):_vm._e()]}},{key:"item.rentenbeginn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatter.dateFormatter(item.rentenbeginn).format("DD.MM.YYYY"))+" "),_c('br'),_c('p')]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status != 'rentner-hinterbliebenrente')?_c('span',[_c('router-link',{staticClass:"no-underline",attrs:{"to":{ name: 'my-contract-details', params: { id: item.vpPid, vtrNr: item.vtrNr, produkt: item.produkt } }}},[_c('v-btn',{staticClass:"rounded-lg table-button",attrs:{"max-width":"40","max-height":"40","elevation":"0","depressed":"","color":"primary"}},[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("$verka_right_neg")])],1)],1)],1):_vm._e(),(item.status == 'rentner-hinterbliebenrente')?_c('span',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"rounded-lg table-button",attrs:{"disabled":"","max-width":"40","max-height":"40","elevation":"0","depressed":"","color":"primary"}},[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("$verka_right_neg")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Die Daten der Verträge im Status"),_c('br'),_vm._v(" \"Rentner Hinterbliebenrentner\""),_c('br'),_vm._v(" können nicht angezeigt werden.")])])],1):_vm._e()]}}],null,false,1490926978)},[_c('template',{slot:"no-data"},[_c('p',{staticStyle:{"margin":"auto","width":"50%","padding":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("table.no-contracts-available"))+" ")])])],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }