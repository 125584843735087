var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 px-5 d-flex flex-row pt-8"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',{attrs:{"id":"dashboard-ag-title-ag"}},[_vm._v(_vm._s(_vm.$t("dienstleister-dashboard.title")))]),_c('p',{staticClass:"pt-4 page-abstract-text"},[_vm._v(_vm._s(_vm.$t("dienstleister-dashboard.title-desc")))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"background-white rounded-5 pa-7"},[_c('v-row',{staticClass:"mb-n8 pl-4 pr-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"verka-table dienstleister-table",attrs:{"id":"verwaltete-data-table","loading":_vm.loadingAGData,"server-items-length":_vm.agData.length,"loading-text":_vm.$t('employees.loading-data'),"headers":_vm.headers,"items":_vm.agData,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.kvpPid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.kvpPid))]),_vm._l((item.kollVtrNrs.length),function(nr){return _c('span',{key:nr},[_c('br')])})]}},{key:"item.nameGesamt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nameGesamt))]),_vm._l((item.kollVtrNrs.length),function(nr){return _c('span',{key:nr},[_c('br')])})]}},{key:"item.kollVtrNrs",fn:function(ref){
var item = ref.item;
return _vm._l((item.kollVtrNrs),function(nr,index){return _c('span',{key:nr},[_vm._v(" "+_vm._s(nr)),(item.kollVtrNrs.length - 1 != index)?_c('span',[_vm._v(",")]):_vm._e(),_vm._v(" "),_c('br')])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg table-button",attrs:{"max-width":"40","max-height":"40","elevation":"0","depressed":"","color":"primary"},on:{"click":function($event){return _vm.switchContext(item)}}},[_c('v-icon',{staticClass:"center-button-icon"},[_vm._v("$verka_right_neg ")])],1),_vm._l((item.kollVtrNrs.length - 1),function(nr){return _c('span',{key:nr},[_c('br')])})]}}])},[_c('template',{slot:"no-data"},[_c('p',{staticStyle:{"margin":"auto","width":"50%","padding":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("table.no-data-available"))+" ")])])],2)],1)],1)],1)]),(!_vm.isDienstleisterSachbearbeiter)?_c('v-row',{staticClass:"mt-0 px-5 d-flex flex-row pt-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-25",attrs:{"id":"dashboard-ag-quick-actions-title"}},[_vm._v(_vm._s(_vm.$t("dienstleister-dashboard.quick-actions-title")))])]),_vm._l((_vm.quickActions),function(action){return [(action.implemented)?_c('verka-quick-action-link',{key:action.title,attrs:{"icon":action.icon,"stats":action.stats,"title":action.title,"description":action.description,"to":action.to,"isLoading":_vm.isLoading,"showLoading":action.showLoading}}):_vm._e()]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }