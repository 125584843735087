var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contractDocuments.contractDocuments && _vm.contractDocuments.contractDocuments.length > 0 && _vm.displayDocumentList)?_c('v-row',[_c('v-col',[_c('h4',{staticClass:"ml-4 mt-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("contract-details.document-list.document-list-header"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"background-white pa-7 rounded-lg"},[_c('v-row',{staticClass:"mb-n8 pl-4 pr-4"},[_c('v-col',{attrs:{"cols":"10"}}),_c('v-col',{attrs:{"cols":"2"}},[(this.$vuetify.breakpoint.name != 'xs')?_c('div',{staticStyle:{"float":"right"}},[_c('v-btn-toggle',[_c('v-btn',{staticClass:"cta",attrs:{"value":"filter"},on:{"click":function($event){return _vm.toggleFilterOptions()}}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Filter anwenden")]),(this.showFilter)?_c('v-icon',{attrs:{"size":"13","right":""}},[_vm._v(" $verka_open")]):_vm._e(),(!this.showFilter)?_c('v-icon',{attrs:{"size":"13","right":""}},[_vm._v(" $verka_close ")]):_vm._e()],1)],1)],1):_vm._e()])],1),(_vm.showFilter)?_c('v-row',{staticClass:"mb-6 mt-0 pt-0"},[_c('v-col',{staticClass:"pl-8 pr-8",attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"filterLabel pb-0 pl-0"},[_vm._v(_vm._s(_vm.$t("contract-details.document-list.document-type-filter")))]),[_c('v-combobox',{attrs:{"items":_vm.filterContractDocuments.filterContractDocuments.fileFilterOptions,"chips":"","append-icon":"$verka_open"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item)+" ")]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","close-icon":"$verka_cross"},on:{"click":select,"click:close":function($event){return _vm.removeFilter('documentType')}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,false,802546529),model:{value:(_vm.documentList_filter),callback:function ($$v) {_vm.documentList_filter=$$v},expression:"documentList_filter"}})]],2)],1):_vm._e(),(this.$vuetify.breakpoint.name == 'xs')?_c('v-row',{staticClass:"mb-6 mt-0 pt-0"},[_c('v-col',{staticClass:"pl-8 pr-8",attrs:{"cols":"12","md":"4"}},[[_c('v-combobox',{attrs:{"label":_vm.$t('contract-details.document-list.document-type-filter'),"items":_vm.filterContractDocuments.filterContractDocuments.fileFilterOptions,"chips":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item)+" ")]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mobile-chip",attrs:{"input-value":selected,"close":"","close-icon":"$verka_cross"},on:{"click":select,"click:close":function($event){return _vm.removeFilter('documentType')}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,false,757856446),model:{value:(_vm.documentList_filter),callback:function ($$v) {_vm.documentList_filter=$$v},expression:"documentList_filter"}})]],2)],1):_vm._e(),_c('v-row',{staticClass:"pl-4 pr-4"},[_c('v-col',{staticClass:"table-padding-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"verka-table contracts-table",attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.contractDocuments.contractDocuments,"options":_vm.options,"server-items-length":_vm.contractDocuments.contractDocuments.length,"hide-default-footer":"","loading":_vm.documentsAreLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.belegdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatter.formatStandard(item.belegdatum))+" ")]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatter.fileSizeFormatter(item.fileSize))+" ")]}},{key:"item.fileId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"width":"40px","height":"40px","min-width":"2.5em","min-height":"2.5em","elevation":"0","depressed":"","color":"primary"},on:{"click":function($event){return _vm.openDocument(item.uuid)}}},[_c('v-icon',{staticClass:"center-button-icon"},[_vm._v("$verka_right_neg ")])],1)],1)]}}],null,false,4023275133)},[_c('template',{slot:"no-data"},[_c('p',{staticStyle:{"margin":"auto","width":"50%","padding":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("table.no-contracts-available"))+" ")])])],2)],1)],1)],1)]),_c('v-col',[(!_vm.isLoading && _vm.totalPages > 0)?_c('v-pagination',{attrs:{"color":"background","value":_vm.currentPage,"length":_vm.totalPages,"total-visible":_vm.pagination_total_visible,"flat":"","prev-icon":"$verka_left_full","next-icon":"$verka_right_full"},on:{"next":_vm.pagginationChanged,"previous":_vm.pagginationChanged,"input":_vm.pagginationChanged}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }